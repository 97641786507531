<template>
  <div>
    <section class="w-100 m-0 p-0">
      <b-container class="mb-md-5" style="min-height:610px">
        <navbar/>
        <div class="d-flex align-items-center h-100">
          <div class="d-inline-flex">
            <b-row class="mb-5 mt-md-5">
              <b-col cols="12" md="6" class="mb-4 mb-md-0">
                  <img src="../assets/img/bearwithisle.svg" alt="policy bear" class="w-75 y-center"/>
              </b-col>
              <b-col cols="12" md="6" class="text-left p-2">
                <h1 class="display-5 rubik-bold my-md-4">"Hi, I'm Policy Bear!</h1>
                <h1 class="mb-md-4">Let's start creating simple arguments from complex data."</h1>
                <b-button :to="`editor/`" variant="border-0" class="nord-btn-primary mr-2 mb-2" size="lg">Start creating!</b-button>
                <a href="#examples" v-smooth-scroll>
                  <b-button variant="outline-primary" class="nord-btn mr-2 mb-2" size="lg">Show examples</b-button>
                </a>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-container>
    </section>
    <wave-seperator/>
    <section id="examples" class="bg-nord3 pt-3 full-height">
      <b-container fluid class="pt-3">
        <h2 class="txt-nord6">These are some awesome examples of cards generated by Policy Bear.</h2>
        <examples/>
      </b-container>
    </section>
  </div>
</template>

<script>
import ArgumentCard from '../components/ArgumentCard.vue';
import DataForm from '../components/DataForm.vue';
import Examples from '../components/Examples.vue';
import Navbar from '../components/Navbar.vue';
import SideBarCard from '../components/SideBarCard.vue';
import WaveSeperator from '../components/WaveSeperator.vue';

export default {
  name: 'Home',
  components: {
    ArgumentCard,
    Navbar,
    DataForm,
    SideBarCard,
    WaveSeperator,
    Examples,
  },
  methods: {
    downloadImage() {
      this.$bvModal.hide('modal-share')
      console.log(document.querySelector('#policy-argument-card'))
      let config = {
        target: '#policy-argument-card',
        captureHiddenClass: 'vti__hidden',
        captureShowClass: 'vti__show',
        captureActiveClass: 'vti__active',
        fileName: 'ImageCapture',
        fileType: 'png',
        returnAction: 'download', // blob, base64, canvas, clipboard, newWindow
        callback: (img) => { return img } // modifies what image is returned
      }
      vue2img().image(config);
    },
    downloadPDF() {
      console.log('print')
      let element = this.$refs.argument;
      console.log(element)
      html2pdf(element);
    },
  },
}
</script>

<style lang="scss" scoped>

</style>

